import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/cards.css";
import localforage from "localforage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [powerBiUrls, setPowerBiUrls] = useState([]);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [role, setRole] = useState("");

  const logout = async () => {
    await localforage.removeItem("token");
    await localforage.removeItem("ID");
    await localforage.removeItem("email");
    navigate("/");
  };

  const handleClick = () => {
    navigate("/createDashboard");
  };
  const handleClick1 = () => {
    navigate("/userManagement");
  };
  const handleClick2 = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const storedEmail = await localforage.getItem("email");

        if (storedEmail) {
          const modifiedEmail = storedEmail.toUpperCase().split("@")[0];
          setEmail(modifiedEmail);
        }
      } catch (error) {
        console.error("Error retrieving email:", error);
      }
    };

    fetchEmail();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = await localforage.getItem("token");

      if (!token) {
        console.error("Token is null or undefined");
        return;
      }

      const userId = await localforage.getItem("ID");
      const roles = await localforage.getItem("userRole");
      setRole(roles);

      if (!userId || !token) {
        console.error("User ID or Token not found");
        return;
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ flex: 1 }} className="cards-container">
      <div className="head">
        <div className="header-img">
          <img
            src="./STC_logo-01.png"
            onClick={logout}
            className="imggg"
            alt="STC Logo"
          />
          <div>
            <h3 style={{ color: "white" }}>Logged In As </h3>
            <h3 style={{ color: "#00aaff" }}>{email}</h3>
          </div>
        </div>
        <div className="logout-btn">
          <button onClick={logout} className="btn">
            Logout
          </button>
        </div>
        <div className="logout-btn">
          <button onClick={handleClick2} className="btn">
            Dashboard
          </button>
        </div>
        <div className="logout-btn">
          {role !== "mod" ? null : (
            <button onClick={handleClick} className="btn">
              Add Dashboard
            </button>
          )}
        </div>
        <div className="logout-btn">
          {role !== "mod" ? null : (
            <button onClick={handleClick1} className="btn">
              Assign Dashboard
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
